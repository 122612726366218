@font-face {
  font-family: "Sofia-Pro";
  src:url("./assets/fonts/Sofia-Pro-UltraLight.woff2") format("woff2"), url("./assets/fonts/Sofia-Pro-UltraLight.woff") format("woff"), url("./assets/fonts/Sofia-Pro-UltraLight.otf") format("opentype");
  font-style: normal;
  font-weight: 100;
}
@font-face {
  font-family: "Sofia-Pro";
  src:url("./assets/fonts/Sofia-Pro-UltraLight-Italic.woff2") format("woff2"), url("./assets/fonts/Sofia-Pro-UltraLight-Italic.woff") format("woff"), url("./assets/fonts/Sofia-Pro-UltraLight-Italic.otf") format("opentype");
  font-style: italic;
  font-weight: 100;
}
@font-face {
  font-family: "Sofia-Pro";
  src:url("./assets/fonts/Sofia-Pro-ExtraLight.woff2") format("woff2"), url("./assets/fonts/Sofia-Pro-ExtraLight.woff") format("woff"), url("./assets/fonts/Sofia-Pro-ExtraLight.otf") format("opentype");
  font-style: normal;
  font-weight: 200;
}
@font-face {
  font-family: "Sofia-Pro";
  src:url("./assets/fonts/Sofia-Pro-ExtraLight-Italic.woff2") format("woff2"), url("./assets/fonts/Sofia-Pro-ExtraLight-Italic.woff") format("woff"), url("./assets/fonts/Sofia-Pro-ExtraLight-Italic.otf") format("opentype");
  font-style: italic;
  font-weight: 200;
}
@font-face {
  font-family: "Sofia-Pro";
  src:url("./assets/fonts/Sofia-Pro-Light.woff2") format("woff2"), url("./assets/fonts/Sofia-Pro-Light.woff") format("woff"), url("./assets/fonts/Sofia-Pro-Light.otf") format("opentype");
  font-style: normal;
  font-weight: 300;
}
@font-face {
  font-family: "Sofia-Pro";
  src:url("./assets/fonts/Sofia-Pro-Light-Italic.woff2") format("woff2"), url("./assets/fonts/Sofia-Pro-Light-Italic.woff") format("woff"), url("./assets/fonts/Sofia-Pro-Light-Italic.otf") format("opentype");
  font-style: italic;
  font-weight: 300;
}
@font-face {
  font-family: "Sofia-Pro";
  src:url("./assets/fonts/Sofia-Pro-Regular.woff2") format("woff2"), url("./assets/fonts/Sofia-Pro-Regular.woff") format("woff"), url("./assets/fonts/Sofia-Pro-Regular.otf") format("opentype");
  font-style: normal;
  font-weight: 400;
}
@font-face {
  font-family: "Sofia-Pro";
  src:url("./assets/fonts/Sofia-Pro-Regular-Italic.woff2") format("woff2"), url("./assets/fonts/Sofia-Pro-Regular-Italic.woff") format("woff"), url("./assets/fonts/Sofia-Pro-Regular-Italic.otf") format("opentype");
  font-style: italic;
  font-weight: 400;
}
@font-face {
  font-family: "Sofia-Pro";
  src:url("./assets/fonts/Sofia-Pro-Medium.woff2") format("woff2"), url("./assets/fonts/Sofia-Pro-Medium.woff") format("woff"), url("./assets/fonts/Sofia-Pro-Medium.otf") format("opentype");
  font-style: normal;
  font-weight: 500;
}
@font-face {
  font-family: "Sofia-Pro";
  src:url("./assets/fonts/Sofia-Pro-Medium-Italic.woff2") format("woff2"), url("./assets/fonts/Sofia-Pro-Medium-Italic.woff") format("woff"), url("./assets/fonts/Sofia-Pro-Medium-Italic.otf") format("opentype");
  font-style: italic;
  font-weight: 500;
}
@font-face {
  font-family: "Sofia-Pro";
  src:url("./assets/fonts/Sofia-Pro-Semi-Bold.woff2") format("woff2"), url("./assets/fonts/Sofia-Pro-Semi-Bold.woff") format("woff"), url("./assets/fonts/Sofia-Pro-Semi-Bold.otf") format("opentype");
  font-style: normal;
  font-weight: 600;
}
@font-face {
  font-family: "Sofia-Pro";
  src:url("./assets/fonts/Sofia-Pro-Semi-Bold-Italic.woff2") format("woff2"), url("./assets/fonts/Sofia-Pro-Semi-Bold-Italic.woff") format("woff"), url("./assets/fonts/Sofia-Pro-Semi-Bold-Italic.otf") format("opentype");
  font-style: italic;
  font-weight: 600;
}
@font-face {
  font-family: "Sofia-Pro";
  src:url("./assets/fonts/Sofia-Pro-Bold.woff2") format("woff2"), url("./assets/fonts/Sofia-Pro-Bold.woff") format("woff"), url("./assets/fonts/Sofia-Pro-Bold.otf") format("opentype");
  font-style: normal;
  font-weight: 700;
}
@font-face {
  font-family: "Sofia-Pro";
  src:url("./assets/fonts/Sofia-Pro-Bold-Italic.woff2") format("woff2"), url("./assets/fonts/Sofia-Pro-Bold-Italic.woff") format("woff"), url("./assets/fonts/Sofia-Pro-Bold-Italic.otf") format("opentype");
  font-style: italic;
  font-weight: 700;
}
@font-face {
  font-family: "Sofia-Pro";
  src:url("./assets/fonts/Sofia-Pro-Black.woff2") format("woff2"), url("./assets/fonts/Sofia-Pro-Black.woff") format("woff"), url("./assets/fonts/Sofia-Pro-Black.otf") format("opentype");
  font-style: normal;
  font-weight: 800;
}
@font-face {
  font-family: "Sofia-Pro";
  src:url("./assets/fonts/Sofia-Pro-Black-Italic.woff2") format("woff2"), url("./assets/fonts/Sofia-Pro-Black-Italic.woff") format("woff"), url("./assets/fonts/Sofia-Pro-Black-Italic.otf") format("opentype");
  font-style: italic;
  font-weight: 800;
}

@keyframes pulse {
  0% {
      transform: scale(0.7);
      opacity: .4;
  }

  100% {
      transform: scale(3);
      opacity: 0;
  }
}

body {
  background: #F6F7FB none;
  margin: 0;
  font-family: "Sofia-Pro", sans-serif;
  font-weight: 400;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

button {
  font-family: "Sofia-Pro", sans-serif;
}

svg {
  fill: currentColor;
  height: 1em;
  width: auto;
}

svg .fill {
  fill: none;
}